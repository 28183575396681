<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="edonProduct_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-edon-product-filter></app-edon-product-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="edonProducts"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import EdonProductFilter from '../../components/filter/FilterEdonProduct'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'
import RubricService from '../../services/rubric/RubricService'

export default {
  name: 'EdonProductListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('edonProduct.id'),
          title: this.$t('edonProduct.title'),
          rubrics: this.$t('edonProduct.rubrics')
        },
        render: {
          rubrics: function (data) {
            const titles = []
            if (Array.isArray(data)) {
              data.forEach(rubricId => {
                titles.push(RubricService.getRubricTitleWithSite(rubricId))
              })
            }

            return titles.join(', ')
          }
        },
        actions: {
          edit: 'edonProduct_edit'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    edonProducts () {
      return this.$store.getters['edonProduct/list']
    },
    totalCount () {
      return this.$store.getters['edonProduct/totalCount']
    },
    page () {
      return this.$store.getters['edonProduct/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appEdonProductFilter: EdonProductFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getEdonProducts () {
      this.$store.dispatch('edonProduct/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('edonProduct/setPage', page)
      this.getEdonProducts()
    }
  },
  async mounted () {
    await this.$store.dispatch('rubric/fetchAll')
    this.getEdonProducts()
  }
}
</script>

<style lang="scss" scoped>

</style>
